var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "my-job-operate" },
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: "left",
            width: "200",
            trigger: "hover",
            "popper-class": "job-operate-popover",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "item-wrap",
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _c("font-icon", {
                staticClass: "item-icon",
                attrs: { href: "#icon-shezhi2" },
              }),
              _c("span", { staticClass: "item-text" }, [_vm._v("设置")]),
            ],
            1
          ),
          _vm.jobInfo.isMyJob && _vm.jobInfo.status != 4
            ? _c(
                "button",
                {
                  staticClass: "job-operate-btn",
                  on: {
                    click: function ($event) {
                      return _vm.showOperationDialog("jobStatusDialog")
                    },
                  },
                },
                [_vm._v("修改职位状态")]
              )
            : _vm._e(),
          _vm.jobInfo.isMyJob && _vm.jobInfo.status == 4
            ? _c(
                "button",
                {
                  staticClass: "job-operate-btn",
                  on: {
                    click: function ($event) {
                      return _vm.showOperationDialog("copyJob")
                    },
                  },
                },
                [_vm._v("复制职位")]
              )
            : _vm._e(),
          _vm.jobInfo.isA2AJob &&
          _vm.jobInfo.canPublishToMarket &&
          _vm.jobInfo.status != 4
            ? _c(
                "button",
                {
                  staticClass: "job-operate-btn",
                  on: {
                    click: function ($event) {
                      return _vm.showOperationDialog("editJobPublishDialog")
                    },
                  },
                },
                [_vm._v("修改市场状态")]
              )
            : _vm._e(),
          _vm.jobInfo.canDelete
            ? _c(
                "button",
                {
                  staticClass: "job-operate-btn",
                  on: {
                    click: function ($event) {
                      return _vm.showOperationDialog("jobDeleteDialog")
                    },
                  },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
        ]
      ),
      _c("job-status-dialog", {
        ref: "jobStatusDialog",
        on: {
          "update-item": function ($event) {
            return _vm.$emit("refresh")
          },
          showProjectCreateDialog: _vm.showProjectCreateDialog,
        },
      }),
      _c("project-create-dialog", {
        ref: "projectCreateDialog",
        on: { "refresh-project-list": _vm.refreshPorjectList },
      }),
      _c("job-publish-dialog", {
        ref: "jobPublishDialog",
        on: {
          refresh: function ($event) {
            return _vm.$emit("refresh")
          },
        },
      }),
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.deleteLoading,
              expression: "deleteLoading",
            },
          ],
          ref: "jobDeleteDialog",
          attrs: {
            width: "500px",
            title: "删除职位",
            visible: _vm.jobDeleteDialogVisible,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.jobDeleteDialogVisible = $event
            },
          },
        },
        [
          _c("div", { staticClass: "job-delete-dialog-body" }, [
            _c(
              "p",
              [
                _c("font-icon", {
                  staticClass: "delete-confirm-icon",
                  attrs: { href: "#icon-warning-circle-copy" },
                }),
                _c("span", { staticClass: "text-primary" }, [
                  _vm._v("确认删除职位吗？"),
                ]),
                _c("span", { staticClass: "delete-confirm-text" }, [
                  _vm._v("删除后不可恢复"),
                ]),
              ],
              1
            ),
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.jobDeleteDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.deleteJob } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }