<template>
  <el-dialog
    title="绑定组织架构"
    :visible="dialogVisible"
    width="480px"
    custom-class="bind-department-dialog"
    :close-on-click-modal="false"
    :close-on-press-eacape="false"
    :show-close="false"
    v-loading="loading">
    <div class="bind-department-tip" v-if="!isBatch">
        将职位 <span class="text-primary">{{jobInfo.name}}</span> 绑定到部门：
    </div>
    <div class="bind-department-tip" v-if="isBatch && jobList.length > 0">
            将职位 <span class="text-primary">{{jobList[0].jobName}}</span>{{jobList.length > 1 ? ` 等${jobList.length}个职位` : ''}} 绑定到部门：
    </div>
    <customer-department-select
        ref="departmentSelect"
        :isSearchOnlyLeaf="true"
        :isOnlyLeaf="true"
        :customerId="customerId"
        :departmentId="jobInfo.departmentId"
        @update-department="updateDepartment"
    ></customer-department-select>
    <span slot="footer" class="dialog-footer">
        <el-button @click="cancelHandle">取消</el-button>
        <el-button type="primary" @click="confirmHandle">确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
import CustomerDepartmentSelect from '#/component/common/customer-department-select.vue'
import JobService from '#/js/service/jobService.js';
export default {
    components: {
        CustomerDepartmentSelect
    },
    props: {
        customerId: String,
        jobInfo: {
            type: Object
        },
        isBatch: Boolean,
        jobList: Array
    },
    data() {
        return {
            dialogVisible: false,
            loading: false,
            departmentId: ""
        }
    },
    methods: {
        show() {
            if(this.jobInfo.departmentId) {
                this.departmentId = this.jobInfo.departmentId;
            }
            this.dialogVisible = true;
        },
        updateDepartment(department) {
            this.departmentId = department.id;
        },
        cancelHandle() {
            this.dialogVisible = false;
            this.departmentId = "";
        },
        confirmHandle() {
            if(!this.departmentId) {
                shortTips("请选择要绑定的部门!");
                return;
            } else {
                if(this.isBatch) {
                    this.$emit('bind-department-confirm', this.jobList, this.departmentId)
                    this.dialogVisible = false;
                    this.departmentId = "";
                }else {
                    this.loading = true;
                    JobService.bindJobDepartment({
                        jobId: this.jobInfo.id,
                        departmentId: this.departmentId
                    }).then(res => {
                        this.loading = false;
                        shortTips("绑定成功");
                        this.$emit('bind-department-confirm');
                        this.dialogVisible = false;
                        this.departmentId = "";
                    }).catch(err => {
                        this.loading = false;
                        console.log(err);
                    })
                }
            }
        },
        reset() {
            this.$refs.departmentSelect && this.$refs.departmentSelect.reset();
        }
    }
}
</script>

<style lang="scss" scope>
.bind-department-tip {
    margin-bottom: 20px;
}
</style>

<style lang="scss">
.department-select.el-select-dropdown {
    max-width: 440px;
}
</style>