var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "职位绑定项目",
        visible: _vm.dialogVisible,
        width: "480px",
        "custom-class": "job-bind-project-dialog",
        "close-on-click-modal": false,
        "close-on-press-eacape": false,
        "show-close": false,
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "selected-list" },
            _vm._l(_vm.hasBindList, function (project, index) {
              return _c(
                "span",
                {
                  key: project.projectId,
                  staticClass: "selected-item",
                  class: project.canOperate ? "" : "disabled",
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(project.projectName) +
                      "\n                "
                  ),
                  project.canOperate
                    ? _c("i", {
                        staticClass: "el-icon-circle-close",
                        on: {
                          click: function ($event) {
                            return _vm.handleRemove(project, index)
                          },
                        },
                      })
                    : _vm._e(),
                ]
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "project-select" },
            [
              _c("span", { staticClass: "project-select-label" }, [
                _vm._v("选择项目"),
              ]),
              _c(
                "el-select",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.selectLoading,
                      expression: "selectLoading",
                    },
                  ],
                  attrs: {
                    "popper-class": "job-bind-project-select",
                    filterable: "",
                    placeholder: "输入项目名称",
                  },
                  on: { change: _vm.handleSelect },
                  model: {
                    value: _vm.value,
                    callback: function ($$v) {
                      _vm.value = $$v
                    },
                    expression: "value",
                  },
                },
                _vm._l(_vm.projectList, function (item) {
                  return _c("el-option", {
                    key: item.projectId,
                    attrs: { label: item.projectName, value: item.projectId },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancelHandle } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.confirmLoading, type: "primary" },
              on: { click: _vm.confirmHandle },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }