var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      attrs: {
        title: "绑定组织架构",
        visible: _vm.dialogVisible,
        width: "480px",
        "custom-class": "bind-department-dialog",
        "close-on-click-modal": false,
        "close-on-press-eacape": false,
        "show-close": false,
      },
    },
    [
      !_vm.isBatch
        ? _c("div", { staticClass: "bind-department-tip" }, [
            _vm._v("\n      将职位 "),
            _c("span", { staticClass: "text-primary" }, [
              _vm._v(_vm._s(_vm.jobInfo.name)),
            ]),
            _vm._v(" 绑定到部门：\n  "),
          ])
        : _vm._e(),
      _vm.isBatch && _vm.jobList.length > 0
        ? _c("div", { staticClass: "bind-department-tip" }, [
            _vm._v("\n          将职位 "),
            _c("span", { staticClass: "text-primary" }, [
              _vm._v(_vm._s(_vm.jobList[0].jobName)),
            ]),
            _vm._v(
              _vm._s(
                _vm.jobList.length > 1 ? ` 等${_vm.jobList.length}个职位` : ""
              ) + " 绑定到部门：\n  "
            ),
          ])
        : _vm._e(),
      _c("customer-department-select", {
        ref: "departmentSelect",
        attrs: {
          isSearchOnlyLeaf: true,
          isOnlyLeaf: true,
          customerId: _vm.customerId,
          departmentId: _vm.jobInfo.departmentId,
        },
        on: { "update-department": _vm.updateDepartment },
      }),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.cancelHandle } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.confirmHandle } },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }