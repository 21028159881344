<template>
    <el-dialog
        title="职位绑定项目"
        :visible="dialogVisible"
        width="480px"
        custom-class="job-bind-project-dialog"
        :close-on-click-modal="false"
        :close-on-press-eacape="false"
        :show-close="false"
    >
        <div v-loading="loading">
            <div class="selected-list">
                <span
                    class="selected-item"
                    v-for="(project, index) in hasBindList"
                    :key="project.projectId"
                    :class="project.canOperate ? '' : 'disabled'"
                >
                    {{ project.projectName }}
                    <i
                        class="el-icon-circle-close"
                        v-if="project.canOperate"
                        @click="handleRemove(project, index)"
                    ></i>
                </span>
            </div>
            <div class="project-select">
                <span class="project-select-label">选择项目</span>
                <el-select
                    popper-class="job-bind-project-select"
                    v-loading="selectLoading"
                    v-model="value"
                    filterable
                    placeholder="输入项目名称"
                    @change="handleSelect"
                >
                    <el-option
                        v-for="item in projectList"
                        :key="item.projectId"
                        :label="item.projectName"
                        :value="item.projectId"
                    >
                    </el-option>
                </el-select>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelHandle">取消</el-button>
            <el-button
                :loading="confirmLoading"
                type="primary"
                @click="confirmHandle"
                >确定</el-button
            >
        </span>
    </el-dialog>
</template>

<script>
import emitter from "@src/js/mixins/emitter";
import ProjectService from "#/js/service/projectService.js";
export default {
    name: "JobBindProjectDialog",
    mixins: [emitter],
    data() {
        return {
            dialogVisible: false,
            loading: false,
            selectLoading: false,
            confirmLoading: false,
            hasBindList: [],
            projectList: [],
            value: "",
        };
    },
    props: {
        jobId: String,
        customerId: String,
        CustomerDepartmentId: String,
    },
    methods: {
        show() {
            this.dialogVisible = true;
            this.getHasBindList();
            this.getProjectList();
        },
        getHasBindList() {
            this.loading = true;
            ProjectService.getJobAlreadyBindingProjects(this.jobId)
                .then((res) => {
                    res.forEach((el) => {
                        el.projectName = el.projectName.replace("$#$", "-");
                    });
                    this.hasBindList = res;
                    // console.log(this.hasBindList);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        getProjectList() {
            this.selectLoading = true;
            ProjectService.getJobCanBindingProjects({
                JobId: this.jobId,
                CustomerId: this.customerId,
                CustomerDepartmentId: this.CustomerDepartmentId,
            })
                .then((res) => {
                    res.forEach((el) => {
                        el.projectName = el.projectName.replace("$#$", "-");
                    });
                    this.projectList = res;
                    // console.log(this.projectList);
                })
                .finally(() => {
                    this.selectLoading = false;
                });
        },
        handleSelect(id) {
            console.log(id);
            let selectedIndex = -1;
            this.projectList.some((item, index) => {
                if (item.projectId == id) {
                    selectedIndex = index;
                    return true;
                }
            });
            let selectedItem = this.projectList[selectedIndex];
            selectedItem.canOperate = true;
            this.hasBindList.push(selectedItem);
            this.projectList.splice(selectedIndex, 1);
            this.value = "";
        },
        handleRemove(item, index) {
            this.hasBindList.splice(index, 1);
            this.projectList.unshift(item);
        },
        cancelHandle() {
            this.dialogVisible = false;
        },
        confirmHandle() {
            if (this.loading || this.confirmLoading) {
                return false;
            }
            if (this.hasBindList.length == 0) {
                shortTips("请至少选择一个项目！");
                return false;
            }
            let projectIds = [];
            this.hasBindList.forEach((item) => {
                projectIds.push(item.projectId);
            });
            this.confirmLoading = true;
            ProjectService.editProjectJobBindings({
                JobId: this.jobId,
                ProjectIds: projectIds,
            })
                .then((res) => {
                    shortTips("绑定成功！");
                    let bindingProjects = {};
                    if (res && res.length > 0) {
                        res.forEach((item) => {
                            bindingProjects[item.projectId] = item.projectName;
                        });
                    }
                    this.dispatch(
                        "JobDetail",
                        "updateBindingProjects",
                        bindingProjects
                    );
                    this.dialogVisible = false;
                })
                .finally(() => {
                    this.confirmLoading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.job-bind-project-dialog {
    .selected-list {
        padding-top: 5px;
        padding-bottom: 10px;

        .selected-item {
            display: inline-block;
            position: relative;
            padding: 0 7px;
            margin-right: 10px;
            margin-bottom: 10px;
            line-height: 20px;
            font-size: 14px;
            color: $primary;
            background-color: #ebf8f5;

            &.disabled {
                color: #ccc;
                background-color: #f5f5f5;
            }

            &:hover .el-icon-circle-close {
                display: block;
            }

            .el-icon-circle-close {
                display: none;
                position: absolute;
                top: -6px;
                right: -6px;
                cursor: pointer;
            }
        }
    }

    .project-select {
        display: flex;
        align-items: center;

        .el-select {
            flex: 1;
            flex-grow: 1;
        }

        &-label {
            font-size: 14px;
            color: #666;
            margin-right: 14px;
        }
    }
}
</style>
<style lang="scss">
.job-bind-project-select {
    width: 370px;
}
</style>