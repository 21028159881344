<template>
<div class="my-job-operate">
    <el-popover
        placement="left"
        width="200"
        trigger="hover"
        popper-class="job-operate-popover"
    >   
        <div class="item-wrap" slot="reference">
            <font-icon
                class="item-icon"
                href="#icon-shezhi2"
            ></font-icon>
            <span class="item-text">设置</span>
        </div>
        
        <button class="job-operate-btn" v-if="jobInfo.isMyJob && jobInfo.status != 4" @click="showOperationDialog('jobStatusDialog')">修改职位状态</button>
        <button class="job-operate-btn" v-if="jobInfo.isMyJob && jobInfo.status == 4" @click="showOperationDialog('copyJob')">复制职位</button>
        <!-- && jobInfo.isPublic -->
        <button class="job-operate-btn" v-if="jobInfo.isA2AJob && jobInfo.canPublishToMarket && jobInfo.status != 4" @click="showOperationDialog('editJobPublishDialog')">修改市场状态</button>
        <button class="job-operate-btn" v-if="jobInfo.canDelete" @click="showOperationDialog('jobDeleteDialog')">删除</button>
    </el-popover>

    <job-status-dialog 
        ref="jobStatusDialog"
         @update-item="$emit('refresh')"
         @showProjectCreateDialog="showProjectCreateDialog"
    ></job-status-dialog>
    <project-create-dialog ref="projectCreateDialog" @refresh-project-list="refreshPorjectList"></project-create-dialog>
    <job-publish-dialog ref="jobPublishDialog" @refresh="$emit('refresh')"></job-publish-dialog>
    <!-- <ban-recommend-dialog ref="banRecommendDialog" v-if="jobInfo.isMyJob && jobInfo.acceptedHeadhuntersCount > 0"></ban-recommend-dialog> -->
    <!-- v-if="jobInfo.canDelete" -->
    <el-dialog
        width="500px"
        title="删除职位"
        ref="jobDeleteDialog"
        :visible.sync="jobDeleteDialogVisible"
        v-loading="deleteLoading"
        
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        append-to-body
    >
        <div class="job-delete-dialog-body">
            <p>
                <font-icon class="delete-confirm-icon" href="#icon-warning-circle-copy"></font-icon>
                <span class="text-primary">确认删除职位吗？</span>
                <span class="delete-confirm-text">删除后不可恢复</span>
            </p>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="jobDeleteDialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="deleteJob">确 定</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
// import BanRecommendDialog from "./ban-recommend-dialog.vue";
import JobPublishDialog from "#/component/common/dialog/jobPublishDialog.vue";
import JobStatusDialog from "#/component/common/dialog/jobStatusDialog.vue";
import ProjectCreateDialog from '#/views/project/component/create-project-dialog.vue';
import { job as jobUrl } from '#/js/config/api.json';
export default {
    components: {
        // BanRecommendDialog,
        JobPublishDialog,
        JobStatusDialog,
        ProjectCreateDialog
    },
    props: {
        jobInfo: Object,
        customerId: String,
        customerDepartmentId: String | Number
    },
    data() {
        return {
            jobDeleteDialogVisible: false,
            deleteLoading: false
        }
    },
    methods: {
        showProjectCreateDialog() {
            this.$refs.projectCreateDialog.show();
        },
        refreshPorjectList() {
            this.$refs.jobStatusDialog.refreshPorjectList();
        },
        showOperationDialog(command) {
            switch(command) {
                case 'jobStatusDialog': 
                    this.$refs.jobStatusDialog.initDialog({
                        type: 'single',
                        checkedArr: [this.jobInfo],
                        jobStatusValue: this.jobInfo.status,
                        emergencyValue: this.jobInfo.emergencyDegree,
                        difficultyValue: this.jobInfo.difficultDegree,
                        jobId: this.jobInfo.id,
                        customerId: this.customerId,
                        customerDepartmentId: this.customerDepartmentId,
                    });
                    break;
                case 'jobDeleteDialog': 
                    this.jobDeleteDialogVisible = true;
                    console.log(this.jobDeleteDialogVisible)
                    break;
                case 'editJobPublishDialog':
                    this.$refs.jobPublishDialog.show(this.jobInfo, true);
                    break;
                case 'copyJob':
                    // location.href = `/#/jobCreate/copy?jobId=${this.jobInfo.id}`;
                    window.open(`/#/jobCreate/copy?jobId=${this.jobInfo.id}`);
                    break;
                default:
                    this.$refs[command].show(this.jobInfo);
            }
        },
        deleteJob() {
            let jobId = this.jobInfo.id ? this.jobInfo.id : this.jobInfo.jobId,
                params = {};
            params.reason = "";
            params.status = 0;
            params.jobId = jobId;
            params.__RequestVerificationToken = this.$store.state.verificationToken.verificationToken;
            this.deleteLoading = true;
            _request({
                url: jobUrl.job_handler.replace(/\%p/ig, jobId),
                method: "POST",
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                data: params
            }).then((res) => {
                this.deleteLoading = false;
                this.jobDeleteDialogVisible = false;
                shortTips('职位删除成功');
                // location.replace('/Headhunting/MyCompany.html#/jobList');
                location.replace('/#/jobList');
                // this.$emit("refresh");
            }).catch(err => {
                this.deleteLoading = false;
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.my-job-operate {
    display: inline-block;
    .el-dropdown-link {
        color: #9b9b9b;
        line-height: 20px;
    }
    .operation-icon {
        width: 14px;
        height: 14px;
        margin-bottom: 3px;
    }
    .job-delete-dialog-body {
        margin: 10px 0;
        margin-left: -20px;
        text-align: center;
        line-height: 40px;
        .delete-confirm-icon {
            width: 40px;
            height: 40px;
            margin-right: 10px;
            margin-top: -5px;
        }
        p {
            margin-bottom: 0;
            font-size: 18px;
            &:nth-of-type(1) {
                display: inline-block;
            }
        }
        .delete-confirm-text {
            font-size: 16px;
            padding-left: 20px;
        }
    }
}
</style>
<style lang="scss">
.job-operate-list.el-dropdown-menu {
    margin-top: 5px;
    padding: 0;
    .el-dropdown-menu__item {
        padding: 6px 20px;
        line-height: 1.5;
    }
    .popper__arrow {
        display: none;
    }
}

.job-operate-popover{
    .job-operate-btn{
        width: 100%;
        font-size: 14px;
        height: 30px;
        &:hover{
            background: #fafafa;
        }
    }
}
</style>
